const type = {
  CATEGORY: 'CATEGORY',
  PARAMETER: 'PARAMETER',
  TRANSPORT_TYPE: 'TRANSPORT_TYPE',
  REASON: 'REASON',
  LOCATIONS: 'LOCATIONS',
  COST_OPTION: 'cost_option',
  MOU_SHIPPER: 'MOU',
  HOLIDAY: 'HOLIDAY',
};

export default type;
