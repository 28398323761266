<template>
  <v-container fluid>
    <v-row class="my-n6">
      <v-col v-if="inputLabel && !hideLabel" cols="auto" :md="labelWidth" class="mt-1">
        <span class="body-2">
          {{inputLabel}}
        </span>
        <h3 v-if="$attrs.rules" class="error--text d-inline">
          *
        </h3>
      </v-col>
      <!-- <v-col cols="auto" class="mt-2">
        :
      </v-col> -->
      <v-col :cols="12" md>
        <v-text-field
          v-if="!isTextArea"
          v-model="displayValue"
          :label="inputLabel"
          required
          :clearable="!readOnly"
          solo
          outlined
          :disabled="readOnly || disabled"
          dense
          :prefix="prefix"
          flat
          v-bind="$attrs"
        ></v-text-field>
        <v-textarea
          v-else
          v-model="displayValue"
          :label="inputLabel"
          required
          :prefix="prefix"
          :disabled="readOnly || disabled"
          :clearable="!readOnly"
          solo
          outlined
          dense
          flat
          :rows="1"
          auto-grow
          v-bind="$attrs"></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CommonTextInput',
  props: {
    inputLabel: String,
    isTextArea: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    value: {},
    labelWidth: {
      type: Number,
      default: 2,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      default: '',
    },
  },
  computed: {
    displayValue: {
      get() {
        return this.value ? this.value : '-';
      },
      set(modifiedValue) {
        this.$emit('input', modifiedValue);
      },
    },
  },
};
</script>

<style>

</style>
