<template>
  <v-container fluid>
    <v-row class="my-n6">
      <v-col cols="auto" :md="2" class="mt-2">
        <span class="body-2">
          {{inputLabel}}
        </span>
        <h3 v-if="$attrs.rules" class="error--text d-inline">
          *
        </h3>
      </v-col>
      <!-- <v-col cols="auto" class="mt-2">
        :
      </v-col> -->
      <v-col :cols="12" md>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="displayValue"
              :label="inputLabel"
              required
              :clearable="!readOnly"
              :disabled="readOnly"
              solo
              outlined
              dense
              flat
              readonly
              v-bind="{...attrs, ...$attrs}"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="displayValue"
            @input="menu = false"
            :disabled="readOnly"
            locale="id"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import {
  dateFormat as _dateFormat,
  getUnixTime as _getUnixTime,
} from '../../helper/commonHelpers';

export default {
  name: 'CommonDateInput',
  props: {
    inputLabel: String,
    isTextArea: {
      type: Boolean,
      default: false,
    },
    value: {},
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      localVal: '',
    };
  },
  watch: {
    value(val) {
      if (val) this.localVal = dayjs(val).format('DD/MM/YYYY');
    },
  },
  computed: {
    displayValue: {
      get() {
        return this.dateFormat(this.value);
      },
      set(modifiedValue) {
        this.$emit('input', this.getUnixTime(modifiedValue));
      },
    },
  },
  methods: {
    dateFormat: (t) => _dateFormat(t),
    getUnixTime: (s) => _getUnixTime(s),
  },
};
</script>

<style>

</style>
