import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const getListMasterPatner = async (filters) => {
  try {
    if (!filters.sort) {
      filters = {
        ...filters,
        sort: 'companyName,asc',
      };
    }
    const res = await axios.get(`${urls.masterPartnerList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchDetailRekanan = async (companyId) => {
  try {
    const res = await axios.get(`${urls.detailMasterPartner}/${companyId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchInformationCooperation = async (mouId) => {
  try {
    const res = await axios.get(`${urls.fetchDetailMasterRekanan}/${mouId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchAdminDetail = async (companyId) => {
  try {
    const res = await axios.get(`${urls.fetchAdminDetailRekanan}/${companyId}/admin`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchDataLocation = async (companyId) => {
  try {
    const res = await axios.get(`${urls.fetchDetailMasterPartner}/${companyId}/location`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
const fetchDataTransporter = async (companyId) => {
  try {
    const res = await axios.get(`${urls.fetchDetailMasterPartner}/${companyId}/transport-type`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchPatnerHistory = async (companyId, page, size) => {
  try {
    const filter = {
      page,
      size,
    };
    const res = await axios.get(`${urls.rekananHistory}/${companyId}/filter`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchFixedPrice = async (companyId) => {
  try {
    const res = await axios.get(
      `${urls.fetchDetailMasterPartner}/${companyId}/transport-type/limit`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchSetPriceFCL = async (companyId) => {
  try {
    const res = await axios.get(`${urls.fetchDetailMasterPartner}/${companyId}/route/FCL`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchSetPrice = async (companyId) => {
  try {
    const res = await axios.get(`${urls.fetchDetailMasterPartner}/${companyId}/route/LCL`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchSetPriceBackhauling = async (companyId) => {
  try {
    const res = await axios.get(
      `${urls.fetchDetailMasterPartner}/${companyId}/route/FCL_BACKHAULING`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateMasterPartner = async (companyId, name) => {
  try {
    const res = await axios.patch(`${urls.detailMasterPartner}/${companyId}?abbreviation=${name}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateEmailMasterPartner = async (userId, email) => {
  try {
    const res = await axios.patch(`${urls.rekananHistory}/${userId}/admin?email=${email}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateStatusApi = async (companyId, status, url) => {
  try {
    const res = await axios.patch(
      `${urls.updateApiTransporter}/${companyId}/openapi?isOpenApi=${status}&&callbackUrl=${url}`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateStatusCompany = async (companyId, status) => {
  try {
    const res = await axios.patch(
      `${urls.detailMasterPartner}/${companyId}/status?status=${status}`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getListMasterPatner,
  fetchDetailRekanan,
  fetchInformationCooperation,
  fetchAdminDetail,
  fetchDataLocation,
  fetchDataTransporter,
  fetchPatnerHistory,
  fetchFixedPrice,
  fetchSetPrice,
  fetchSetPriceBackhauling,
  fetchSetPriceFCL,
  updateMasterPartner,
  updateStatusApi,
  updateEmailMasterPartner,
  updateStatusCompany,
};
