<template>
  <v-container fluid>
    <v-row class="my-n6">
      <v-col cols="auto" :md="3" class="mt-1">
        <p class="body-2" style="word-wrap: break-word">
          {{inputLabel}}
        </p>
        <h3 v-if="$attrs.rules" class="error--text d-inline">
          *
        </h3>
      </v-col>
      <!-- <v-col cols="auto" class="mt-2">
        :
      </v-col> -->
      <v-col :cols="12" md>
        <v-autocomplete
          v-model="displayValue"
          :label="inputLabel"
          required
          solo
          :disabled="readOnly"
          :clearable="!readOnly"
          outlined
          dense
          flat
          v-bind="$attrs"
          @change="e => $emit('change', e)"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CommonAutocompleteInput',
  props: {
    inputLabel: String,
    value: {},
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchInput: {},
    };
  },
  computed: {
    displayValue: {
      get() {
        return this.value;
      },
      set(modifiedValue) {
        this.$emit('input', modifiedValue);
      },
    },
  },
  watch: {
    searchInput(modifiedValue) {
      if (!modifiedValue) return;
      this.$emit('searchInputChanged', modifiedValue);
    },
  },
};
</script>

<style>

</style>
