import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

/**
 * INVOICE ORACLE
 * @param {String<REGULAR|FIXED_PRICE>} type selection list filter
 */

const getListInvoiceOracle = async (filter, type, source = {}) => {
  try {
    const res = await axios.get(`${urls.oracleInvoice}/filter/${type}?${querystring.stringify(filter)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getDetailInvoiceOracle = async (id) => {
  try {
    const res = await axios.get(`${urls.oracleInvoice}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const resendInvoiceOracle = async (id) => {
  try {
    const res = await axios.patch(`${urls.oracleInvoice}/resend/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * ACCRUAL GL
 * @param {String<ONCALL|FIXED_PRICE>} type selection list filter
 */

const getListAccrualGl = async (filter, type, source = {}) => {
  try {
    const res = await axios.get(`${urls.accrualGl}/filter/${type}?${querystring.stringify(filter)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getDetailAccrualGl = async (id) => {
  try {
    const res = await axios.get(`${urls.accrualGl}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const resendAccrualGl = async (id) => {
  try {
    const res = await axios.patch(`${urls.accrualGl}/resend/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getListInvoiceOracle,
  resendInvoiceOracle,
  getDetailInvoiceOracle,
  getListAccrualGl,
  getDetailAccrualGl,
  resendAccrualGl,
};
