export default {
  MASTER_CUSTOMERS: 'Master Customers',
  ACCUAL_GL: 'Accual GL',
  INVOICE_ON_CALL: 'Invoice On Call',
  INVOICE_ORACLE: 'Invoice Oracle',
  CUSTOMER_CODE: 'Kode Customer',
  CUSTOMER_NAME: 'Nama Customer',
  SEARCH_INVOICE_ORACLE: 'Cari Invoice Oracle',
  SEARCH_CUSTOMER: 'Cari Customer',
  SEARCH_ACCRUAL_GL: 'Cari Accrual GL',
  SHIP_TO_LOCATION: 'Ship To Location',
  BILL_TO_LOCATION: 'Bill To Location',
  RESEND_TO_ORACLE: 'Kirim Ulang ke Oracle',
  CUSTOMER_DETAIL: 'Detail Customer',
  ACCRUAL_GL: 'Accrual GL',
  INVOICE_NO: 'No Invoice',
  SEND_DATE_INVOICE: 'Tanggal Kirim Invoice',
  JOURNAL_NUMBER: 'No Jurnal',
  TRANSACTION_DATE: 'Tanggal Transaksi',
  INVOICE_DATE: 'Tanggal Kirim Invoice',
};
