import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

/**
 * get order list
 * @param {Object} filter list parameters
 * @param {Number} filter.page page number
 * @param {Number} filter.size page size
 */
const getListShipments = async (filter, status, source) => {
  try {
    const res = await axios.get(`${urls.listShipments}/status/${status}?${querystring.stringify(filter)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListShipmentsConfirmation = async (filter, type, source = {}) => {
  try {
    const res = await axios.get(
      `${urls.listShipments}/confirmation/${type}?${querystring.stringify(filter)}`,
      {
        cancelToken: source.token,
      },
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const confirmation = async (cargoId, form, type) => {
  try {
    const res = await axios.patch(`${urls.listShipments}/${cargoId}/confirmation/${type}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const assignDriver = async (form) => {
  try {
    const res = await axios.patch(`${urls.listShipments}/assign/driver`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListAssignDriver = async (filter) => {
  try {
    const res = await axios.get(
      `${urls.listShipments}/assign/driver?${querystring.stringify(filter)}`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListAssignTransporter = async (filter) => {
  try {
    const res = await axios.get(
      `${urls.listShipments}/assign/transport?${querystring.stringify(filter)}`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const autoAssign = async (form) => {
  try {
    const res = await axios.put(`${urls.shipmentCargo}/assign/auto-assign`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListShipmentDetail = async (id) => {
  try {
    const res = await axios.get(`${urls.listShipments}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * get order by order id
 * @param {Number} orderId order id
 */
const getOrderById = async (orderId) => {
  try {
    const res = await axios.get(`${urls.orderById}/${orderId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * get sub order list
 * @param {Number} orderId order id
 * @param {Object} filter list parameters
 * @param {Number} filter.page page number
 * @param {Number} filter.size page size
 */
const fetchSubOrderList = async (orderId, filter) => {
  try {
    const res = await axios.get(`${urls.subOrderList}/${orderId}?${querystring.stringify(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const shipmentSummary = async () => {
  try {
    const res = await axios.get(`${urls.shipmentSummary}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getCombinedOrder = async (shipmentGroupId, source) => {
  try {
    const res = await axios.get(`${urls.combined}?shipmentGroupId=${shipmentGroupId}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getWantCombine = async (form) => {
  try {
    const res = await axios.post(urls.wantCombine, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const submitCombineLcl = async (shipmentGroupId, _pickupDate, shipmentId) => {
  try {
    const data = {
      shipmentGroupId,
      pickupDate: _pickupDate,
      shipmentId,
    };
    const res = await axios.post(urls.submitCombineLcl, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListVehicle = async () => {
  try {
    const getTransportType = await axios.get(urls.listTransport);
    return getTransportType.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const saveRoute = async (form) => {
  try {
    const res = await axios.post(urls.route, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListTopTransporters = async (filters, source) => {
  try {
    const res = await axios.get(`${urls.topListTransporters}?${querystring.stringify(filters)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const saveShipmentDocumentAdditional = async (form, shipmentsId, config, source) => {
  try {
    const res = await axios.post(urls.shipmentDocumentAdditional(shipmentsId), form, config, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const cancelShipment = async (id, form) => {
  try {
    const res = await axios.patch(`${urls.listShipments}/${id}/status/canceled`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const completeOrder = async (id, form) => {
  try {
    const res = await axios.patch(`${urls.listShipments}/${id}/status/finished`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const baspConfirmation = async (id, form) => {
  try {
    const res = await axios.post(`${urls.listShipments}/${id}/basp/confirmation`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const saveSelecetedTransporter = async (form, source = {}) => {
  try {
    const res = await axios.put(`${urls.saveSelectedTransporter}`, form, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListTopTransportersLcl = async (filters, source = {}) => {
  try {
    const result = await axios.get(`${urls.topListTransporters}?${querystring.stringify(filters)}`, {
      cancelToken: source.token,
    });
    return result.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getRoute = async (shipmentGroupId, source = {}) => {
  try {
    const result = await axios.get(urls.getRoute(shipmentGroupId), {
      cancelToken: source.token,
    });
    return result.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteRoute = async (shipmentGroupId, shipmentCargoId) => {
  try {
    const result = await axios.delete(urls.deleteRoute(shipmentGroupId, shipmentCargoId));
    return result.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getMainCost = async (shipmentId) => {
  try {
    const result = await axios.get(urls.mainCost(shipmentId));
    return result.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getUnitPrice = async (shipmentId) => {
  try {
    const result = await axios.get(urls.unitPrice(shipmentId));
    return result.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getUnitCost = async (shipmentId) => {
  try {
    const res = await axios.get(`${urls.orderTransaction}/${shipmentId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getShipmentQty = async (id, unit, isDelivery) => {
  try {
    let url = urls.shipmentQty(id, unit);
    if (isDelivery) url = urls.shipmentQtyDelivery(id, unit);
    const result = await axios.get(url);
    return result.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const saveShipmentQty = async (id, unit, details, isDelivery) => {
  try {
    let url = urls.shipmentQty(id, unit);
    if (isDelivery) url = urls.shipmentQtyDelivery(id, unit);
    const result = await axios.put(`${url}`, details);
    return result.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const saveCost = async (id, form) => {
  try {
    const res = await axios.put(`${urls.orderTransaction}/${id}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const continueShipping = async (shipmentsCargoId, data, source) => {
  try {
    const res = await axios.patch(`${urls.shipmentCargo}/${shipmentsCargoId}/basp/confirmation`, data, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListAdditionalCost = async (filters) => {
  try {
    const { name } = filters;
    filters = {
      ...filters,
      type: 'qIn(cost)',
      sort: 'name,desc',
      isActive: 'qistrue',
    };
    if (name) filters.name = `qLike(${name})`;
    const res = await axios.get(`${urls.reasonList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getReasonCancelOrder = async (filters) => {
  try {
    const { name } = filters;
    filters = {
      ...filters,
      sort: 'name,asc',
      type: 'qIn(cancellation)',
      isActive: 'qistrue',
    };
    if (name) filters.name = `qlike(${name})`;
    const res = await axios.get(`${urls.reasonList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const cargoStatus = async (cargoId) => {
  try {
    const res = await axios.get(`${urls.shipmentCargo}/${cargoId}/status`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const isHasWaitingOrder = async () => {
  try {
    const res = await axios.get(urls.waitingShipment);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateStatusCargo = async (cargoId, form) => {
  try {
    const res = await axios.patch(`${urls.listShipments}/${cargoId}/status/cargo`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateStatusCargoAtLocation = async (cargoId, form) => {
  try {
    const res = await axios.patch(`${urls.listShipments}/${cargoId}/status`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const bast = async (shipmentCargoId, form, source) => {
  try {
    const res = await axios.patch(`${urls.listShipments}/${shipmentCargoId}/status/bast`, form, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const basp = async (shipmentCargoId, form, source) => {
  try {
    const res = await axios.post(`${urls.listShipments}/${shipmentCargoId}/basp`, form, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const assignMultimoda = async (data) => {
  try {
    const res = await axios.put(`${urls.listShipments}/${data.id}/multimoda`, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getMultimodaInfo = async (id) => {
  try {
    const res = await axios.get(`${urls.listShipments}/${id}/multimoda`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const reportOrderAccident = async (shipmentCargoId, data, source) => {
  try {
    const res = await axios.post(`${urls.listShipments}/${shipmentCargoId}/accident`, data, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export {
  getListShipments,
  confirmation,
  assignDriver,
  getListAssignDriver,
  getListAssignTransporter,
  getListShipmentsConfirmation,
  getListShipmentDetail,
  getOrderById,
  fetchSubOrderList,
  shipmentSummary,
  getWantCombine,
  submitCombineLcl,
  getListVehicle,
  saveRoute,
  getListTopTransporters,
  saveShipmentDocumentAdditional,
  cancelShipment,
  completeOrder,
  baspConfirmation,
  saveSelecetedTransporter,
  getListTopTransportersLcl,
  getRoute,
  deleteRoute,
  getMainCost,
  getShipmentQty,
  saveShipmentQty,
  getUnitPrice,
  getUnitCost,
  saveCost,
  getListAdditionalCost,
  continueShipping,
  getReasonCancelOrder,
  cargoStatus,
  isHasWaitingOrder,
  getCombinedOrder,
  updateStatusCargo,
  updateStatusCargoAtLocation,
  bast,
  basp,
  assignMultimoda,
  getMultimodaInfo,
  reportOrderAccident,
  autoAssign,
};
