export default {
  CURLY: '{{ }}',
  BACK: 'Kembali',
  EXIT: 'Keluar',
  NEXT: 'Selanjutnya',
  YES: 'Ya',
  NO: 'Tidak',
  CANCEL: 'Batal',
  EDIT: 'Ubah',
  PROCESS: 'Proses',
  SAVE: 'Simpan',
  DELETE: 'Hapus',
  CREATED_BY: 'Dibuat Oleh',
  UPDATED_BY: 'Diubah Oleh',
  STATUS: 'Status',
  CSV_UPLOAD_BUTTON_LABEL: 'Unggah CSV',
  CSV_TEMPLATE_BUTTON_LABEL: 'Template CSV',
  UPLOAD_STATUS_BUTTON_LABEL: 'Status Upload',
  NUMBER: 'No',
  DATE: 'Tanggal',
  NOTE: 'Catatan',
  ACTION: 'Aksi',
  VIEW_DETAIL: 'Lihat Detail',
  DETAIL: 'Detail',
  NAME: 'Nama',
  CLOSE: 'Tutup',
  RESET: 'Reset',
  ADD: 'Tambah',
  CREATE: 'Buat',
  CREATED_BY_TABLE_HEADER_LABEL: 'Dibuat Oleh',
  WARNING: 'Perhatian',
  SUBMIT: 'Submit',
  CHOOSE: 'Pilih',
  SAVE_AS_DRAFT: 'Save as Draft',
  ADDENDUM: 'Addendum',
  FROM_DATE: 'Dari Tanggal',
  TO_DATE: 'Sampai Tanggal',
  LOADING_TEXT: 'Loading... Please wait',
  ERROR_MESSAGE: 'Terjadi Kesalahan',
  NO_DATA: 'Tidak Ada Data',
  UPLOAD: 'Unggah',
  APPLY: 'Terapkan',
  UPDATE: 'Update',
  DOWNLOAD: 'Download',
  SEARCH: 'Cari',
  SELECT: 'Pilih',
  RESEND: 'Kirim Ulang',

  commonTablePagination: (page, length, size, totalEntry) => {
    if (length >= 1) {
      if (page === 1 && totalEntry <= size) {
        return `1 - ${length} dari ${totalEntry}`;
      }
      if (page === 1) {
        return `1 - ${size} dari ${totalEntry}`;
      }
      if (page >= 2) {
        const from = (page - 1) * size + 1;
        const to = from + (length - 1);
        return `${from} - ${to} dari ${totalEntry}`;
      }
    }
    return '';
  },
};
